/* App CSS */
import './App.scss'
import './assets/css/flex.scss'
import ErrorBoundary from './components/ErrorBoundary'
import SplashLoading from './components/SplashLoading'
import { AppContextProvider } from './data/AppContext'
import { connect } from './data/connect'
import { loadFactorData } from './data/factor/factor.actions'
import { loadConfData } from './data/sessions/sessions.actions'
import { loadUserData } from './data/user/user.actions'
import './i18n/config'
import MFactorRoutes from './routes/MFactorRoutes'
import WebRoutes from './routes/WebRoutes'

/* Theme variables */
import './theme/variables.scss'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'vazirmatn/Vazirmatn-font-face.css'

setupIonicReact()

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AppContextProvider>
        <Suspense fallback={<SplashLoading />}>
          <IonicAppConnected />
        </Suspense>
      </AppContextProvider>
    </ErrorBoundary>
  )
}

interface StateProps {
  darkMode: boolean
  lang: string
}

interface DispatchProps {
  loadConfData: typeof loadConfData
  loadUserData: typeof loadUserData
  loadFactorData: typeof loadFactorData
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  lang,
  loadConfData,
  loadUserData,
  loadFactorData,
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { i18n } = useTranslation()

  useEffect(() => {
    loadUserData()
    loadConfData()
    loadFactorData()
    return () => {}
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!!lang && !firstTime) {
      window.location.reload()
    }
    if (!!lang) {
      setFirstTime(false)
    }
    if (lang === 'fa') {
      document.documentElement.setAttribute('dir', 'rtl')
      document.documentElement.setAttribute('lang', 'fa')
      i18n.changeLanguage('fa')
      document.title =
        import.meta.env.VITE_APP_NAME === 'taak' ? 'تاک کلود' : 'میکرو فاکتور'
    } else if (lang === 'en') {
      document.documentElement.setAttribute('dir', 'ltr')
      document.documentElement.setAttribute('lang', 'en')
      i18n.changeLanguage('en')
      document.title =
        import.meta.env.VITE_APP_NAME === 'taak' ? 'Taakcloud' : 'µFactor'
    }
    return () => {}
  }, [lang]) // eslint-disable-line

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        {import.meta.env.VITE_APP_NAME === 'taak' && <WebRoutes />}
        {import.meta.env.VITE_APP_NAME === 'mFactor' && <MFactorRoutes />}
      </IonReactRouter>
    </IonApp>
  )
}

export default React.memo(App)

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    lang: state.user.lang,
  }),
  mapDispatchToProps: { loadConfData, loadUserData, loadFactorData },
  component: React.memo(IonicApp),
})
